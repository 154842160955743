import React, { useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Footer from './components/Footer'


function App() {

  useEffect(() => {
    // if (window.location.protocol !== 'https:') {
    //   window.location.protocol = 'https:';
    // }
  }, [])

  return (
    <Router >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/impressum" component={Impressum} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
