import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DownArrow from '../icons/DownArrow.svg';

function Article(props) {
    const [expanded, setExpandet] = useState(false);
    const [visible, setVisible] = useState(false);
    const refBody = useRef(null);
    useEffect(() => {
        if (refBody.current.clientHeight < refBody.current.scrollHeight) {
            setVisible(true);
        }
    }, [])
    return (
        <Wrapper expanded={expanded} onClick={() => setExpandet(!expanded)}>
            <Picture picture={props.picture} />
            <Body ref={refBody} expanded={expanded}>
                <Head>{props.head}</Head>
                <Text>
                    {props.text.map(t => <p key={t} >{t}</p>)}
                </Text>
                {!expanded && visible && <Blur />}
            </Body>
            {visible && <Toggler expanded={expanded}><img alt="" src={DownArrow} width="100%" height="100%" /></Toggler>}

        </Wrapper>
    );
}

export default Article;

const Toggler = styled.div`
    position: absolute;
    bottom: -10px;
    left: calc(50% - 15px);
    heigth: 10px;
    width: 30px;
    ${props => props.expanded && 'transform: rotate(180deg);'}
    @media screen and (max-width: 560px) { 
        display: none;
    }
`;

const Text = styled.div`
    @media screen and (max-width: 420px) { 
        margin-left: -25vw;
    }
`;

const Wrapper = styled.div`
position: relative;
    cursor: pointer;
    ${props => !props.expanded && 'max-height: 20vw;'}
    display: flex;
    flex-direction: row;
    margin: 20px;
    transition: transform .3s;
    :hover{
        transform: scale(1.03);
    }
`;

const Head = styled.h1`
    margin: 0px;
`;

const Body = styled.div`
    max-height: 240px;
    overflow: hidden;   
    ${props => props.expanded && 'max-height: 99999px; height: auto; overflow: visible;'}
    transition: height 15s ease-out !important;
    width: 70%;
    margin: 10px;
    padding-left: 20px;
    position: relative;
    
`;

const Picture = styled.div`
    background-image: url(${props => props.picture});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 20vw;
    height: 20vw;
    max-height: 240px;
    max-width: 240px;
    border-radius: 50%;
  `;

const Blur = styled.div` 

    @media screen and (min-width: 420px) { 
        position: absolute;
        top: 0;
        left: 0;
    
        right: 0;
        bottom : 0;
        background-image: linear-gradient( rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 97% ); 
     }
`;