import React from 'react';
import styled from 'styled-components';

function Footer() {
    return (
        <Wrapper>
            <Container>
                <Helper>
                    <Box>
                        <h4>Adresse</h4>
                        <p>Rheingauer Weinmuseum</p>
                        <p>Rheinstraße 2</p>
                        <p>65385 Rüdesheim</p>
                        <Button><Link href="mailto:info@rheingauer-weinmuseum.de"><p>Mail: info@rheingauer-weinmuseum.de</p></Link></Button>
                    </Box>
                    <Box>
                        <h4>Links</h4>
                        <Button><Link href="https://www.broemserburg.de/"><p>www.Broemserburg.de</p></Link></Button>
                        <Button><Link href="https://www.rheingau.com"><p>www.Rheingau.de</p></Link></Button>
                        <Button><Link href="https://www.ruedesheim.de"><p>www.Rüdesheim.de</p></Link></Button>
                    </Box>
                    <Box>
                        <h4>Bankverbindung</h4>
                        <p>Rheingauer Volksbank</p>
                        <p>BIC: GENODE51RGG</p>
                        <p>IBAN: DE30 5109 1500 0010 1330 92</p>
                        <Button><Link href="/pdf/Satzung.pdf"> <h4>Satzung</h4></Link></Button>
                        <Button><Link href="/pdf/Aufnahmeantrag.pdf"> <h4>Aufnahmeantrag</h4></Link></Button>
                    </Box>
                    <Box>
                        <h4>Spendenkonto</h4>
                        <p>Rheingauer Volksbank eG</p>
                        <p>BIC: GENODE51RGG</p>
                        <p>IBAN: DE70 5109 1500 1300 1734 93</p>
                        <Button><Link href="#/impressum"> <h4>Impressum</h4></Link></Button>
                        <Button><Link href="#/"> <h4>Startseite</h4></Link></Button>
                    </Box>
                </Helper>
            </Container>
        </Wrapper >
    );
}

export default Footer;

const Link = styled.a`
    color: black;
`;

const Helper = styled.div`
    justify-content: space-between;
    margin: 20px;
    display: flex;
    
    @media screen and (max-width: 880px) { 
        flex-direction: column;
     }
`;

const Wrapper = styled.div`
                    background-color: rgb(235, 235, 235)
                `;

const Container = styled.div`
                    max-width: 1200px;
                    margin: auto;
                    padding: calc(2vw);
                `;

const Button = styled.div`
                    color: black !important;
                    cursor: pointer;
                    transition: transform .3s;
    :hover{
                transform: scale(1.03);
    }
`;

const Box = styled.div`
    margin 10px 0 10px 0;
    padding: 10px;
`;