import React, { useState, useEffect } from 'react';
import imgAktuelles from '../pictures/Aktuelles.jpg'
import imgHistorie from '../pictures/Historie.jpg'
import imgMitgliedschaft from '../pictures/Mitgliedschaft.jpg'
import imgEngagement from '../pictures/Engagement.jpg'
import imgZeiten from '../pictures/Zeiten.jpg'
import Head from '../pictures/Head.jpg'
import Article from '../components/Article';
import broemserburgLogo from '../icons/broemserburgLogo.svg';
import donationQR from '../icons/donationQR.png'

import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import useEvent from '../Hooks/useEvent.js';

function Home() {
    const [newsletterModalOut, setNewsletterModalOut] = useState(false);
    const [donationModalOut, setDonationModalOut] = useState(false);
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const loadTop = () => {
        document.getElementById("root").scrollTo = 0;
        window.scrollTo(0, 0);
    }
    const sendMail = async (adress) => {
        try {
            const res = await fetch(`http://46.101.181.189:8080/sendemail/${adress}`);
            const json = await res.json();
            console.log(json);
            setError(false);
            setNewsletterModalOut(false)
        } catch {
            setError(true);
        }
    }
    useEvent('load', loadTop);
    useEffect(loadTop);
    return (
        <div>
            {newsletterModalOut &&
                <Modal>
                    <p>Melden Sie sich für den Newsletter an und wir halten Sie auf dem Laufenden.</p>
                    <p style={{ fontSize: "15px" }}><input type="checkbox" checked={checked} onClick={() => setChecked(!checked)} /> Ich möchte regelmäßig den Newsletter erhalten.</p>

                    <EmailInput placeholder="max.mustermann@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {error && <p style={{ color: 'red' }}>Es ist ein Fehler aufgetreten.</p>}
                    <Register
                        disabled={!checked || !email || !email.includes('@') || !email.includes('.')}
                        onClick={checked && email.includes('@') && email.includes('.') ? () => sendMail(email) : () => { }}
                        style={{ marginTop: "0px;" }}>
                        <p>Abschicken</p></Register>
                    <Register onClick={() => setNewsletterModalOut(false)} style={{ marginTop: "0px;" }}><p>Abbrechen</p></Register>
                </Modal>}
            {donationModalOut &&
                <Modal wide>
                    <h4>Danke für Ihre Spende!</h4>
                    <Row>
                        <Col width="50%">
                            <img alt="" src={donationQR} height="auto" width="100%" />
                            <p style={{ fontSize: "16px" }}>Scannen Sie den QR-Code mit Ihrer Banking-App für eine Überweisungsvorlage.</p>
                        </Col>

                        <Col width="50%">
                            <h4>Spendenkonto</h4>
                            <p>Förderverein Rheingauer Weinmusueum und Kulturdenkmal Brömserburg e.V. </p>
                            <p>Rheingauer Volksbank eG</p>
                            <p>BIC: GENODE51RGG</p>
                            <p>IBAN: DE70 5109 1500 1300 1734 93</p>
                        </Col>
                    </Row>
                    
                    <Register onClick={() => setDonationModalOut(false)} style={{ marginTop: "0px;" }}><p>Zurück</p></Register>
                </Modal>}
            <HeadPic />
            <Header>
                <H1>Wir bewahren der Zukunft die Vergangenheit</H1>
                <p>Melden Sie sich für den Newsletter an und wir halten Sie auf dem Laufenden.</p>
                <br></br>
                <br></br>
                <br></br>
                <Register onClick={() => setDonationModalOut(true)}><p>Spenden</p></Register>
                <Register onClick={() => setNewsletterModalOut(true)}><p>Newsletter erhalten</p></Register>
            </Header>
            <Logo><img alt="" src={broemserburgLogo} width="100%" height="100%" /></Logo>
            <Container id="container">
                <Fade>
                    {articles.map((article) => <Article key={article.head} head={article.head} text={article.text} picture={article.picture} />)}
                    <Map>
                        <a href="mailto:info@rheingauer-weinmuseum.de"><Button><h4>Mail: info@rheingauer-weinmuseum.de</h4></Button></a>
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20526.349203765883!2d7.900637670213195!3d49.977728214716116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcb9af26c99a374db!2sBr%C3%B6mserburg%20-%20Die%20Burg%20am%20Rhein%20-%20R%C3%BCdesheim!5e0!3m2!1sde!2sde!4v1571950917782!5m2!1sde!2sde" width="100%" height="450" frameborder="0" style={{ "border": "0" }} allowfullscreen=""></iframe>
                    </Map>
                </Fade>
            </Container>
        </div >
    );
}

export default Home;


const articles = [
    {
        head: 'Aktuelles',
        text: ['Die über 2.000 Exponate des Museums wurden nach der Rekonstituierung des Fördervereins Rheingauer Weinmusueum und Kulturdenkmal Brömserburg e.V. im Zeitraum von August 2018 bis Ende Februar 2019 fotografiert, katalogisiert und in eine spezielle Datenbank zur Erfassung von Ausstellungsobjekten aufgenommen. Dies wurde im Rahmen der anstehenden Sanierungsarbeiten in und an der Brömserburg notwendig.', 'Unter Aufsicht eines Archäologen wurden die Exponate einzeln in säurefreiem Papier und säurefreien Kartons verpackt und sachgerecht in sicheren Räumlichkeiten gelagert.', 'Mit Hilfe der modernen Datenbank lassen sie sich jederzeit schnell wieder auffinden. So warten die Exponate in ihrem "Versteck" darauf, in einem neuen Ausstellungskonzept ihren Platz zu finden.'],
        picture: imgAktuelles,
    },
    {
        head: 'Historie',
        text: ['Gegründet wurde der "Förderverein Rheingauer Weinmusueum und Kulturdenkmal Brömserburg e.V." bereits im Jahr 1948, um das in der Brömserburg in Rüdesheim bestehende Museum zu betreiben und weiter zu entwickeln.',
            'Besonderer Dank gilt Reinhardt Asbach für die jahrzehntelange finanzielle und ideelle Unterstützung des Vereins. Er hat zusammen mit engagierten Bürgern wesentlich dazu beigetragen, dass der Förderverein Rheingauer Weinmusueum und Kulturdenkmal Brömserburg e.V. heute Eigentümer einer Sammlung von mehr als 2.000 wertvollen Exponaten aus über fünf Jahrtausenden ist. ',
            'Zählten bis 2018 die Verwahrung, Pflege, Ergänzung und museale Verwendung der Exponate zu den wichtigsten Aufgaben des Vereins, fungiert er heute auch als Förderverein, der sowohl Spenden, als auch Fördermittel entgegennehmen kann.',
            'Seiner satzungsmäßigen Bestimmung entsprechend wurde der Verein im November 2023 von "Rheingauer Weinmuseum e.V." in "Förderverein Rheingauer Weinmuseum und Kulturdenkmal Brömserburg e.V." umbenannt.',
            'Damit wird es öffentlichen Einrichtungen, Firmen und Einzelpersonen möglich, sich finanziell für die kontinuierliche Weiterentwicklung des Vereins sowie der zukünftigen Ausstellungs-Gestaltung zu engagieren.'],
        picture: imgHistorie,
    },
    {
        head: 'Mitgliedschaft',
        text: ['Wir freuen uns, wenn wir Sie oder Ihr Unternehmen als Mitglied des "Fördervereins Rheingauer Weinmusueum und Kulturdenkmal Brömserburg e.V." begrüßen dürfen. Durch Ihre Mitgliedschaft unterstützen Sie kontinuierlich die Sicherung, Weiterentwicklung und Präsentation einer der kulturhistorisch wichtigsten Sammlungen des Rheingaus. ',
            'Jugendliche ab 16 Jahre, Rentner, Studenten, Gemeinnützige Einrichtungen: 20€ p.a.',
            'Privatpersonen: 60€ p.a.',
            'Städte und Gemeinden, Unternehmen, Körperschaften, Öffentliche Einrichtungen, Institutionen: 150€ p.a.'],
        picture: imgMitgliedschaft,
    },
    {
        head: 'Engagement',
        text: ['Wir bieten Ihnen als Förderer eine außergewöhnliche Plattform, um Ihre Verantwortung für ein einzigartiges Kulturgut öffentlichkeitswirksam zu zeigen.',
            'Werden auch Sie Teil der Rheingauer Kulturgeschichte, indem Sie uns unterstützen!',
            'Sie können dies in unterschiedlichster Form tun, hier sind der Kreativität keine Grenzen gesetzt. Sprechen Sie uns an!',
            'Gemeinsam mit Ihnen wollen wir an die über 70jährige Vereinstradition anknüpfen und mit Hilfe der Vereins-Exponate eine lebendige Erlebniswelt schaffen, die lokal, regional und international für Aufsehen sorgt. Unterstützt werden wir dabei durch professionelle Medienarbeit, in die auch Ihr Engagement einfließen wird.'],
        picture: imgEngagement,
    },
    {
        head: 'Öffnungszeiten',
        text: ['Die Brömserburg und das Weinmuseum bleiben für die Zeit der Renovierung und Instandsetzung geschlossen. Aktuelle Entwicklungen werden in der Tagespresse und über diese Website kommuniziert.'],
        picture: imgZeiten,
    }
]

const EmailInput = styled.input`
    -webkit-appearance: none
    border: none;
    width:90%;
    height: 40px;
    font-size: 25px;
    font-family: 'Oswald', sans-serif;
    border-bottom: 3px solid rgb(177, 142, 34);
`;

const Modal = styled.div`
        ${prop => prop.wide ? "" : "width: 325px"};
        background-color: white;
        border-radius: 40px;
        z-index:100;
        //color: rgb(177, 142, 34);
        padding: 40px;
        box-shadow: 1px 1px 50px 50px rgba(150,150,150, 0.4);
        position: absolute;
        left: 50%;
        top:50%;
        
        transform: translate(-50%, -50%);
    `;

const Register = styled.div`
        height: 60px;
        width: 240px;
        color: white;
        text-align: center;
        margin: auto;
        border-radius: 40px;
        line-height: 60px;
        cursor: pointer;
        transition: transform .3s;
        ${prop => !prop.disabled ?
        `
        :hover{
            transform: scale(1.03);
        }
        background-color: rgb(177, 142, 34);
        `
        :
        `
        background-color: rgba(177, 142, 34, 0.7);
        `
    }

    `;

const HeadPic = styled.div`
        height : calc(100vh - 80px);
    background-image: url(${Head});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        `;

const Header = styled.div`
            position: absolute;
            color: white;
            top: calc(40vh - 80px);
            left: 25vw;
            width: 50vw;
            text-align: center;
            border-radius: 40px;
            background-color: rgba(150,150,150,0.4);
            box-shadow: 1px 1px 50px 50px rgba(150,150,150, 0.4);
        
    @media screen and (max-width: 880px) {
                position: absolute;
            color: white;
            top: calc(27vh - 80px);
            left: 7.5vw;
            width: 85vw;
            text-align: center;
            border-radius: 40px;
            background-color: rgba(150,150,150,0.4);
            box-shadow: 1px 1px 50px 50px rgba(150,150,150, 0.4);
         }
    
    `;

const Logo = styled.div`
        position: absolute;
        top: calc(20px + 1%);
        left calc(20px + 1%);
        width: calc(100px + 4%);
    `;

const H1 = styled.h1`
        font-size: 50px;
        color: white;
        margin: 0;
        z-index: 1;
    @media screen and (max-width: 880px) {
                font - size: calc(20px + 2vh);
         }
    `;

const Container = styled.div`
        max-width: 1200px;
        margin: auto;
        padding: 2vw;
    `;

const Map = styled.div`
        margin: 70px 20px;
    `;

const Button = styled.div`
        cursor: pointer;
        transition: transform .3s;
    :hover{
                transform: scale(1.007);
        }
    `;

const Row = styled.div`
    justify-content: space-between;
    margin: 0px;
    display: flex;
    
    @media screen and (max-width: 1020px) { 
        flex-direction: column;
     }
    `;

const Col = styled.div`
    margin 10px 10px 10px 0;
    padding: 10px;
    ${props => props.width && `
        @media screen and (min-width: 1020px) { 
            width: ${props.width};
        }
    `}
`;